import { Collapse, Row, Col, Typography, Checkbox, message, Modal, Button } from "antd";
import classNames from "classnames";
import NodeMappingButton from "components/NodeMapping/NodeMappingButton";
import { InfrastructureType } from "pages/Infrastructures/InfrastructureDataType";
import { infrastructureTypeValue } from "pages/Infrastructures/Infrastructures.d";
import React, { useCallback, useMemo, useState } from "react";
import { getNodeSubTypeDisplayName, getNodeTypeDisplayValue } from "utils/nodeTypeDisplay";
import { ThermostatIcon, SensorIcon, AirPumpIcon, VoltMeterIcon } from "components/Icons";
import {
  NodeType,
  usePositionConfigurationByPositionIdsQuery,
  useUpdatePositionConfigurationMutation,
  useUpdateTaskMutation,
  useChangeBatteryMutation as useChangeBattery,
  NodeSubType,
} from "pacts/app-webcore/hasura-webcore.graphql";
import { NodeTypeCodeIdentifiers } from "pages/InfrastructureDetails/ContractorMappedNodes/MappedNodes.d";
import { NodeHealth } from "pages/Node/ContractorNodeList/ContractorNodeList.d";
import errorHandler from "errorHandler";
import BaseACModelDropdown from "components/BaseACModelDropdown";
import { FormData } from "pages/NodeManagement/CreateNodeLifeCycleEvent";
import ChangeBatteryModal from "pages/NodeManagement/ChangeBatteryModal/ChangeBatteryModal";
import { isShowBaseACModelDropdown } from "pages/Key/key.helper";
import { TaskParentEntityType, TaskStatus } from "../KeyEntryJobChecklist.d";
import NodeMappingButtonInfra from "../NodeMappingButtonInfra/NodeMappingButtonInfra";

import { ETaskType } from "../types";
import { statusIcon } from "./helpers";

type TaskCardProps = {
  task: any;
  isCurrentKeyEntry?: boolean;
  splitIntoRooms?: boolean;
  isCompressor?: boolean;
  infraData?: {
    infraId?: string;
    infraType?: string;
    infraName?: string;
    meterPositionId?: string;
    slotName?: string;
    nodeMacId?: string;
  };
  reloadInfra: () => void;
  currentKeyEntryId?: number;
  nodeHealthList: NodeHealth[];
  count: number;
};

const taskTypeIcon = (nodeType?: string | null) => {
  switch (nodeType) {
    case NodeType.Aircon:
      return <ThermostatIcon className="thermostatIcon" />;
    case NodeType.Door:
    case NodeType.Occupancy:
      return <SensorIcon className="sensorIcon" />;
    case NodeType.Energy:
      return <AirPumpIcon className="airPumpIcon" />;
    default:
      return <VoltMeterIcon className="voltMeterIcon" />;
  }
};

const getNodeTypeCode = (nodeType: any) => {
  if (!nodeType) return "";
  return NodeTypeCodeIdentifiers.find((NodeTypeCodeIdentifier) => NodeTypeCodeIdentifier.type === nodeType)?.code || "";
};

const TaskCard = ({
  task,
  splitIntoRooms,
  isCompressor,
  infraData,
  currentKeyEntryId,
  nodeHealthList = [],
  count,
  isCurrentKeyEntry,
  reloadInfra,
}: TaskCardProps) => {
  const [baseAcModelId, setBaseAcModelId] = useState<string | null>();
  const [baseAcModelIdInput, setBaseAcModelIdInput] = useState<string | null>();
  const [openChangeBatteryModal, setOpenChangeBatteryModal] = useState(false);
  const [changeBatteryValue, setChangeBatteryValue] = useState<FormData>({});

  const [changeBattery] = useChangeBattery({
    onError: errorHandler.handleError,
    onCompleted: (data: any) => {
      message.success(`Record life cycle event battery change successfully for node ${data.changeBattery?.nodeMacId}`);
      setOpenChangeBatteryModal(false);
    },
  });

  const onOpenChangeBatteryModal = async (value: any) => {
    setOpenChangeBatteryModal(true);
    setChangeBatteryValue({ nodeMacId: value });
  };

  const onChangeBattery = async (data: FormData) => {
    if (data.nodeMacId) {
      await changeBattery({
        variables: {
          nodeMacId: data.nodeMacId,
          comment: data.comment,
        },
      });
    }
  };

  const helpText = useMemo(() => {
    return (
      <div>
        <p className="font-italic fs-sm">Do not insert battery before installing node</p>
        {task?.action?.instructionLink && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={task?.action?.instructionLink}
            className="font-italic mb-xl"
          >
            Instruction link{" "}
          </a>
        )}
      </div>
    );
  }, [task]);

  const getNodeHealth = useCallback(
    (slotId?: string): NodeHealth => {
      return nodeHealthList.find((node) => node.slotId === slotId) as NodeHealth;
    },
    [nodeHealthList]
  );

  const [updateStatus] = useUpdateTaskMutation({
    onCompleted: () => {
      message.success("Update task status completed.");
    },
  });

  const handleUpdateTaskStatus = useCallback(
    async (taskId: string, currentStatus?: string | null) => {
      await updateStatus({
        variables: {
          taskId,
          update: {
            status: currentStatus === TaskStatus.DONE ? TaskStatus.PENDING : TaskStatus.DONE,
            keyEntryId: currentKeyEntryId,
          },
        },
      });
    },
    [updateStatus, currentKeyEntryId]
  );

  usePositionConfigurationByPositionIdsQuery({
    variables: {
      positionIds: [task.roomId],
    },
    onCompleted: ({ positionConfigurations: data }) => {
      setBaseAcModelId(data?.[0].acModelId);
    },
  });

  const taskTitle = useMemo(
    () =>
      !splitIntoRooms &&
      task.taskType === ETaskType.Installation &&
      task.nodeType &&
      task.parentEntityType !== TaskParentEntityType.COMPRESSOR
        ? `Install ${getNodeTypeDisplayValue(task.nodeType || "")}`
        : task.title,
    [splitIntoRooms, task]
  );

  const [updatePositionConfigurationMutation] = useUpdatePositionConfigurationMutation({
    onCompleted() {
      message.success("Base AC Model has been updated.");
      setBaseAcModelId(baseAcModelIdInput);
    },
    onError: errorHandler.handleError,
  });

  const changeBaseACModel = (value: any) => {
    setBaseAcModelIdInput(value);
    updatePositionConfigurationMutation({
      variables: {
        positionConfiguration: {
          positionId: task.roomId,
          acModelId: value,
        },
      },
    });
  };

  const changeBaseACModelWithConfirm = (value: any) => {
    Modal.confirm({
      title: "Confirm changing AC Model",
      onOk: () => {
        changeBaseACModel(value);
      },
      onCancel: () => {
        setBaseAcModelId(null);
      },
    });
  };

  const isShowACModel = useCallback(() => {
    const currentMappedNode = getNodeHealth(task.associatedSlotId);
    if (!isShowBaseACModelDropdown(task.nodeType, task.nodeSubType, currentMappedNode)) return false;
    if (currentMappedNode && currentMappedNode.subType === NodeSubType.AirconDaikin) return false;
    return true;
  }, [getNodeHealth, task.associatedSlotId, task.nodeType, task.nodeSubType]);

  const shouldShowNodeMappingUI = useMemo(
    () =>
      !(
        (!splitIntoRooms || !task.associatedSlotId) &&
        !isCompressor &&
        !(task.parentEntityType === "slot" && infraData?.infraId)
      ),
    [splitIntoRooms, task, isCompressor, infraData]
  );

  return (
    <div className="task-card" key={task.taskId}>
      <Collapse
        expandIconPosition="end"
        bordered={splitIntoRooms}
        className={classNames({ "bg-white": true, disabled: !splitIntoRooms })}
        expandIcon={!splitIntoRooms ? () => null : undefined}
      >
        <Collapse.Panel
          key={task.taskId}
          header={
            <Row align="middle">
              <Col span={4} className="taskTypeIcon">
                {taskTypeIcon(task.nodeType)}
              </Col>
              <Col span={20} className="title justify-content-between d-inline-flex">
                <span className="vertical-align-center">{taskTitle}</span>
                <div className="icon">{splitIntoRooms ? statusIcon(task.status) : <p>x{count}</p>}</div>
              </Col>
            </Row>
          }
        >
          {shouldShowNodeMappingUI && (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Typography.Title>{isCompressor ? task.keyName : task.slotName}</Typography.Title>
                <Typography.Text>
                  {isCompressor && infraData?.infraType
                    ? infrastructureTypeValue[infraData?.infraType as keyof typeof infrastructureTypeValue]?.label
                    : getNodeSubTypeDisplayName(task.nodeType || "", task.nodeSubType || "")}
                </Typography.Text>
              </Col>
              <Col span={24}>
                {isCompressor || (task.parentEntityType === "node_to_slot_mapping" && infraData?.infraId) ? (
                  <>
                    {helpText}
                    <NodeMappingButtonInfra
                      infraId={infraData?.infraId}
                      infraName={infraData?.infraName}
                      infraType={new InfrastructureType(infraData?.infraType)}
                      meterPositionId={infraData?.meterPositionId}
                      nodeMacId={infraData?.nodeMacId}
                      slotName={infraData?.slotName}
                      disabled={!isCurrentKeyEntry}
                      reload={reloadInfra}
                    />
                    {infraData?.nodeMacId && (
                      <Button
                        className="mt-s w-100"
                        onClick={async () => {
                          await onOpenChangeBatteryModal(infraData.nodeMacId);
                        }}
                        disabled={!isCurrentKeyEntry}
                      >
                        Change battery
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    {helpText}
                    {isShowACModel() && (
                      <div className="mb-l">
                        <BaseACModelDropdown
                          baseAcModelId={baseAcModelId}
                          onChange={changeBaseACModelWithConfirm}
                          disabled={!isCurrentKeyEntry}
                          dataTestId="ac-model-dropdown-testid"
                        />
                      </div>
                    )}
                    <NodeMappingButton
                      keyName={task.keyName}
                      roomId={task.roomId}
                      roomName={task.roomName}
                      slotName={task.slotName || ""}
                      nodeType={task.nodeType}
                      nodeSubType={task.nodeSubType}
                      nodeTypeCodeIdentifier={getNodeTypeCode(task.nodeType)}
                      nodeMacInput={getNodeHealth(task.associatedSlotId)?.nodeMacId}
                      disabled={!isCurrentKeyEntry}
                    />
                    {getNodeHealth(task.associatedSlotId)?.nodeMacId && (
                      <Button
                        className="mt-s w-100"
                        onClick={async () => {
                          await onOpenChangeBatteryModal(getNodeHealth(task.associatedSlotId)?.nodeMacId);
                        }}
                        disabled={!isCurrentKeyEntry}
                      >
                        Change battery
                      </Button>
                    )}
                  </>
                )}
              </Col>
            </Row>
          )}
          <Row>
            <Col className="mt-m text-right w-100">
              <span className="font-weight-bold">
                Mark as done
                <Checkbox
                  className="ml-s"
                  checked={task.status === TaskStatus.DONE}
                  onClick={() => handleUpdateTaskStatus(task.taskId, task.status)}
                  id={task.taskId}
                  disabled={
                    (shouldShowNodeMappingUI &&
                      !infraData?.nodeMacId &&
                      !getNodeHealth(task.associatedSlotId)?.nodeMacId) ||
                    !isCurrentKeyEntry
                  }
                />
              </span>
            </Col>
          </Row>
          {openChangeBatteryModal && (
            <ChangeBatteryModal
              formData={changeBatteryValue}
              closeModal={() => setOpenChangeBatteryModal(false)}
              handleOnSubmit={onChangeBattery}
            />
          )}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default TaskCard;

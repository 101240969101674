import React from "react";
import { Link, useParams } from "react-router-dom";

import get from "lodash/get";
import classnames from "classnames";
import { NodeType, Order_By } from "pacts/app-webcore/hasura-webcore.graphql";
import Table from "components/Table/Table";
import { getNodeSubTypeDisplayName } from "utils/nodeTypeDisplay";
import CopyButton from "components/CopyButton";
import { getNodeListLookerDashboardLink } from "utils/link";
import "./NodeListTable.scss";
import useBreakpoint from "hooks/use-breakpoint";
import { isNil } from "lodash";
import useRoleAndPermission from "hooks/use-role-and-permission";
import useLocationDateTime from "hooks/use-location-datetime";
import { ButtonRMA } from "components/ButtonRMA";
import NodeLifeCycleEvent from "pages/NodeManagement/NodeLifeCycleEvent/NodeLifeCycleEvent";
import NodeMobileItem from "../NodeMobileItem/NodeMobileItem";
import { Node, NODE_STATUS_UI_CONFIG, NodeStatus } from "../NodeList/NodeList.d";

const sortOrder = (value: Order_By) => get({ [Order_By.Asc]: "ascend", [Order_By.Desc]: "descend" }, value, "");

const WARNING_SIGNAL_STRENGTH = 20;

const NodeListTable = (props: any) => {
  const { locationId } = useParams<{ locationId: string }>();
  const roleAndPermission = useRoleAndPermission();

  const { formatDateTime, formatFromNow, formatUnixDate } = useLocationDateTime();

  const screen = useBreakpoint();

  const getSortConfig = (sortKey: string) => {
    const { sortBy, sort } = props;
    return {
      onHeaderCell: () => ({
        onClick: () => {
          sortBy(sortKey);
        },
      }),
      sorter: true,
      defaultSortOrder: sortOrder(sort[sortKey]),
    };
  };

  const COLUMN_CONFIG = [
    {
      title: "Node ID",
      dataIndex: "nodeMacId",
      render: (nodeMacId: string) => {
        return (
          <div className={classnames("d-flex align-items-center")}>
            <a target="_blank" rel="noopener noreferrer" href={getNodeListLookerDashboardLink(nodeMacId)}>
              {nodeMacId}
            </a>
            <CopyButton btnClassName="p-none" className="fs-l" input={nodeMacId} />
          </div>
        );
      },
      isShowOnMobile: false,
      isVisible: true,
      width: 200,
      fixed: "left",
    },
    {
      title: "Node Type",
      render: (record: Node) => getNodeSubTypeDisplayName(record.nodeType, record.nodeSubType),
      isShowOnMobile: false,
      isVisible: true,
      width: 200,
      fixed: "left",
    },
    {
      title: "Last Seen",
      render: (record: Node) => (
        <div className="d-flex align-items-center">
          <div
            className={classnames(
              "status-indicator mr-xs",
              `bg-${NODE_STATUS_UI_CONFIG[record.status || NodeStatus.Offline]?.indicatorColor}`
            )}
          />
          <div>{record.lastSeen ? formatFromNow(record.lastSeen) : "Never Seen"}</div>
        </div>
      ),
      ...getSortConfig("node.nodeJoinStatus.lastSeen"),
      isShowOnMobile: false,
      isVisible: true,
      width: 180,
    },
    {
      title: "Key",
      render: ({ key, nodeType }: Node) => {
        if (
          [NodeType.PipeTemp.toString(), NodeType.Energy.toString(), NodeType.FlowMeter.toString()].indexOf(nodeType) >=
          0
        ) {
          return "";
        }
        return key && <Link to={`/locations/${locationId}/keys/${key.positionId}`}>{key.positionName}</Link>;
      },
      ...getSortConfig("position.parentPosition.positionName"),
      isShowOnMobile: false,
      isVisible: true,
    },
    {
      title: "Room",
      render: ({ room, key, nodeType }: Node) => {
        // must use <a> because <Link> does not support hash fragment #room-...
        if (
          [NodeType.PipeTemp.toString(), NodeType.Energy.toString(), NodeType.FlowMeter.toString()].indexOf(nodeType) >=
          0
        ) {
          return "";
        }
        return (
          key && (
            <a href={`/locations/${locationId}/keys/${key.positionId}#room-${room.positionId}`}>{room.positionName}</a>
          )
        );
      },
      ...getSortConfig("position.positionName"),
      isShowOnMobile: false,
      isVisible: true,
    },
    {
      title: "Associated gateway",
      dataIndex: ["associatedGateway", "gatewayName"],
      ...getSortConfig("node.gateway.gatewayName"),
      isShowOnMobile: false,
      isVisible: true,
    },
    {
      title: "Signal Strength",
      render: ({ signalStrength }: Node) => {
        return (
          <span
            className={classnames({
              "text-danger": !isNil(signalStrength) && signalStrength < WARNING_SIGNAL_STRENGTH,
            })}
          >
            {!isNil(signalStrength) ? signalStrength.toFixed(2) : "-"}
          </span>
        );
      },
      ...getSortConfig("node.nodeJoinStatus.signalStrength"),
      isShowOnMobile: false,
      isVisible: true,
      width: 180,
    },
    {
      title: "Node Join",
      dataIndex: "nodeJoin",
      ...getSortConfig("node.nodeJoinStatus.recentJoinCount"),
      isShowOnMobile: false,
      isVisible: true,
      width: 120,
    },
    {
      title: "Boot Time",
      render: ({ bootTime }: Node) => (bootTime ? formatUnixDate(bootTime) : "-"),
      ...getSortConfig("node.nodeJoinStatus.bootTime"),
      isShowOnMobile: false,
      isVisible: true,
      width: 200,
    },
    {
      title: "Mapped On",
      dataIndex: "mappedTime",
      render: (mappedTime: string) => formatDateTime(mappedTime),
      ...getSortConfig("mappedTime"),
      isShowOnMobile: false,
      isVisible: true,
      width: 200,
    },
    {
      title: "FW Version",
      render: ({ firmwareVersion }: Node) => firmwareVersion || "-",
      isShowOnMobile: false,
      isVisible: true,
      width: 120,
    },
    {
      title: "",
      width: 210,
      render: (record: Node) => {
        return <NodeLifeCycleEvent nodeMacId={record.nodeMacId} btnGhost />;
      },
      isVisible: true,
    },
    {
      title: "",
      render: (record: Node) => {
        return <NodeMobileItem node={record} />;
      },
      isVisible: true,
      isShowOnMobile: true,
      onCell: () => ({
        className: "node-mobile-item-cell",
      }),
    },
    {
      title: "Action",
      isVisible: roleAndPermission.isInstaller() || roleAndPermission.isPC(),
      render: (record: Node) => {
        return <ButtonRMA nodeMacRecord={record} />;
      },
    },
  ];

  const columns = COLUMN_CONFIG.filter(
    ({ isVisible, isShowOnMobile }) => isVisible && (screen.desktopUp ? !isShowOnMobile : isShowOnMobile)
  );

  if (screen.mobileAndTabletOnly) {
    return (
      <>
        <div className="d-flex flex-row align-items-center w-100 font-weight-bold text-center">
          <div className="w-45">Node ID</div>
          <div className="w-30">Last seen</div>
          <div className="w-25">Node Type</div>
        </div>
        <Table
          {...props}
          columns={columns}
          mobileView={screen.mobileAndTabletOnly}
          rowKey="nodeMacId"
          id="node-list-table"
          dataItemName="nodes"
          onRow={() => ({ "data-testid": "node-list-row" })}
          classNames={{ "table-node-mobile": screen.mobileAndTabletOnly }}
        />
      </>
    );
  }

  return (
    <Table
      {...props}
      scroll={{ x: 2000 }}
      columns={columns}
      rowKey="nodeMacId"
      id="node-list-table"
      dataItemName="nodes"
      onRow={() => ({ "data-testid": "node-list-row" })}
    />
  );
};

export default NodeListTable;

import { Form, message } from "antd";
import Modal from "antd/lib/modal/Modal";
import errorHandler from "errorHandler";
import { every, isEmpty } from "lodash";
import {
  CreateLocationMutation,
  Sensorflow_Locations_Insert_Input,
  Sensorflow_Organisations_Constraint,
  Sensorflow_Organisations_Update_Column,
  useLocationOneQuery,
  useUpsertLocationMutation,
  useValidateClickupListIdLazyQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GATEWAY_DEFAULT_SETTINGS } from "utils/constants";
import { CreateLocationFormFieldKey, CREATE_LOCATION_FORM_CONFIG } from "./CreateLocationFormConfig";
import { CreateLocationInput, CreateLocationModalProps, FieldData } from "./CreateLocationModal.d";
import "./CreateLocationModal.scss";

const CreateLocationModal = ({ visible, closeModal, locationId, refetchLocations }: CreateLocationModalProps) => {
  const [form] = Form.useForm<CreateLocationInput>();
  const [isValidClickupListLink, setIsValidClickupListLink] = useState<boolean>(true);
  const [clickupListId, setClickupListId] = useState<string>();
  const isEdit = !!locationId;
  const clickupTeamId = process.env.CLICKUP_TEAM_ID || "3670304";
  const clickupApiUrl = process.env.CLICKUP_API_URL || "https://app.clickup.com/";

  // when user have never submitted the form => the input is always valid
  const [validInput, setValidInput] = useState<boolean>(true);

  const { refetch: getLocation } = useLocationOneQuery({
    skip: true,
    onError: errorHandler.handleError,
  });

  useEffect(() => {
    const formatClickupLink = (listId: any) => {
      return `${clickupApiUrl}${clickupTeamId}/v/li/${listId}`;
    };

    if (locationId) {
      getLocation({
        locationId,
      }).then(({ data }) => {
        const { locationOne } = data;
        form.setFieldsValue({
          [CreateLocationFormFieldKey.ORGANIZATION_NAME]: locationOne?.organisation?.name,
          [CreateLocationFormFieldKey.LOCATION_NAME]: locationOne?.locationName,
          [CreateLocationFormFieldKey.TIMEZONE]: locationOne?.timezone,
          [CreateLocationFormFieldKey.CURRENCY]: locationOne?.currency,
          [CreateLocationFormFieldKey.LATITUDE]: locationOne?.origin_latitude,
          [CreateLocationFormFieldKey.LONGITUDE]: locationOne?.origin_longitude,
          [CreateLocationFormFieldKey.CLICKUP_LIST_LINK]: formatClickupLink(locationOne?.clickupListId),
        });
      });
    }
  }, [locationId, getLocation, form, clickupApiUrl, clickupTeamId]);

  const [validateClickupListId, { loading: validatingClickupListId }] = useValidateClickupListIdLazyQuery({
    onCompleted: () => {
      setIsValidClickupListLink(true);
    },
    onError: () => {
      setIsValidClickupListLink(false);
      message.error("ClickUp list provided is not valid. Please create the list using the location root template.");
    },
  });

  const checkFormValdity = React.useCallback(
    async (changedFields, allFields: FieldData[]) => {
      const clickupListLink = form.getFieldValue(CreateLocationFormFieldKey.CLICKUP_LIST_LINK);
      const splitClickupListLink = clickupListLink.split("/");
      const listId = splitClickupListLink[splitClickupListLink.length - 1];
      setClickupListId(listId);

      if (listId) {
        validateClickupListId({
          variables: {
            clickupListId: listId,
          },
        });
      }

      const validatedFields = allFields.filter((f) => !f.validating);
      if (isEmpty(validatedFields)) {
        setValidInput(true);
        return;
      }
      setValidInput(every(validatedFields, (f) => isEmpty(f.errors)));
    },
    [validateClickupListId, form]
  );

  const history = useHistory();

  const [createLocationMutation] = useUpsertLocationMutation({
    onCompleted: (data: CreateLocationMutation) => {
      closeModal();
      if (isEdit) {
        refetchLocations?.();
        message.success("Editted location successfully");
      } else {
        history.push(`/locations/${data.createLocation?.locationId}`);
        message.success("Created a new location");
      }
    },
    onError: errorHandler.handleError,
  });

  const handleClose = () => {
    form.resetFields();
    closeModal();
  };

  const handleSubmit = () => {
    const locationInput: Sensorflow_Locations_Insert_Input = {
      organisation: {
        data: { name: form.getFieldValue(CreateLocationFormFieldKey.ORGANIZATION_NAME) },
        on_conflict: {
          constraint: Sensorflow_Organisations_Constraint.UniqueName,
          update_columns: [Sensorflow_Organisations_Update_Column.Name],
        },
      },
      locationName: form.getFieldValue(CreateLocationFormFieldKey.LOCATION_NAME),
      currency: form.getFieldValue(CreateLocationFormFieldKey.CURRENCY),
      timezone: form.getFieldValue(CreateLocationFormFieldKey.TIMEZONE),
      origin_latitude: parseFloat(form.getFieldValue(CreateLocationFormFieldKey.LATITUDE)),
      origin_longitude: parseFloat(form.getFieldValue(CreateLocationFormFieldKey.LONGITUDE)),
      clickupListId,
      clickupExportDisabled: !!clickupListId,
      otaMaxRetries: GATEWAY_DEFAULT_SETTINGS.OtaMaxRetries,
      otaWindowStartTime: GATEWAY_DEFAULT_SETTINGS.OtaWindowStartTime,
      otaWindowEndTime: GATEWAY_DEFAULT_SETTINGS.OtaWindowEndTime,
    };

    if (isEdit) {
      locationInput.locationId = locationId;
    }

    createLocationMutation({
      variables: {
        locationInput,
      },
    });
  };

  return (
    <Modal
      title={isEdit ? "Edit Location" : "Add Location"}
      okText={isEdit ? "Edit Location" : "Add Location"}
      className="create-location-modal"
      visible={visible}
      confirmLoading={validatingClickupListId}
      onCancel={handleClose}
      onOk={() => form.submit()}
      okButtonProps={{
        disabled: !validInput || !isValidClickupListLink,
      }}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={Object.fromEntries(CREATE_LOCATION_FORM_CONFIG.map((c) => [c.name, c.defaultValue]))}
        onFieldsChange={checkFormValdity}
        onFinish={handleSubmit}
        onFinishFailed={() => setValidInput(false)}
      >
        {CREATE_LOCATION_FORM_CONFIG.map((c) => (
          <Form.Item
            key={c.name}
            label={c.label}
            name={c.name}
            trigger={c.trigger}
            validateTrigger={c.trigger}
            rules={c.rules}
          >
            {c.component}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};

export default CreateLocationModal;

import { Moment } from "moment";
import { LocationWithKeysQuery } from "../../pacts/app-webcore/hasura-webcore.graphql";

export enum AutomationMode {
  Disabled = "NONE",
  SmartSave = "SMARTSAVE",
  SuperSave = "SUPERSAVE",
}

export enum ACMode {
  Auto = 0,
  Cool = 1,
  Dry = 2,
  Fan = 3,
  Heat = 4,
}

export enum DoorStatus {
  Close = 0,
  Open = 1,
  NotUpdated = -1,
}

export enum OccupancyState {
  Unoccupied = 0,
  Occupied = 1,
  NotUpdated = -1,
}

export enum ACStatus {
  Off = 0,
  On = 1,
  NotUpdated = -1,
}

export enum ACFanSpeed {
  Auto = 0,
  Low = 1,
  Medium = 2,
  High = 3,
}

export enum EventStreamType {
  Occupancy = "occupancy",
  Powered = "powered",
  TemperatureSet = "temperature_set",
  Door = "door",
  AutomationModeEvent = "automation_mode",
  FanSpeed = "fan_speed",
  Mode = "mode",
  LowerSetPoint = "min_temp",
  UpperSetPoint = "max_temp",
  OccupancyTimeOutNight = "occ_timeout_night_mins",
  OccupancyTimeoOutDay = "occ_timeout_day_mins",
  DoorSensorTimeOut = "door_sensor_timeout_mins",
  UnoccupiedTemp = "unoccupied_temp",
  ACNightEnd = "ac_night_end",
  ACNightStart = "ac_night_start",
  OccNightEnd = "occ_night_end",
  SpecialInstallationMode = "special_installation_mode",
  DoorSensorEnabled = "enable_door_sensor",
  ACModelId = "ac_model_id",
  Precool = "pre_cool",
  ActiveHours = "Active Hours",
  AutomationHours = "Automation Hours",
  ACSetPoint = "ac_set_point",
  ACStatusEvent = "ac_status",
  ForceOccupancyAutomation = "force_occupancy_automation",
  OperationalMode = "operational_mode",
}

export enum PositionConfigurationRecordType {
  DEFAULT = "DEFAULT",
  CURRENT = "CURRENT",
  ARCHIVE = "ARCHIVE",
}

export enum CommandStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

export type FormPositionConfiguration = {
  automationMode: AutomationMode | null | undefined;
  minTemp: number | null | undefined;
  maxTemp: number | null | undefined;
  heatingMinTemp: number | null | undefined;
  heatingMaxTemp: number | null | undefined;
  hideSetpoint: boolean | null | undefined;
  heatingHideSetpoint: boolean | null | undefined;
  automationHours: any[] | null | undefined;
  activeHours: any[] | null | undefined;
  occTimeoutDayMins: number | null | undefined;
  occTimeoutNightMins: number | null | undefined;
  doorSensorTimeoutMins: number | null | undefined;
  unoccupiedTemp: number | null | undefined;
  heatingUnoccupiedTemp: number | null | undefined;
  freezeProtectionTemp: number | null | undefined;
  actuationTimeoutSeconds: number | null | undefined;
  actingMode: ActingMode | null | undefined;
  operationalMode: string | null | undefined;
  expiredAt: string | null | undefined;
  enableDoorSensor: boolean | null | undefined;
  forceOccupancyAutomation: boolean | null | undefined;
};

export type KeysFilterInput = {
  positionName?: string;
  locationId: string;
  occupancies?: Array<string>;
  doors?: Array<string>;
  acStatuses?: Array<string>;
  automationModes?: Array<string>;
  categories?: Array<string>;
  isDeviatedFromDefaultConfig?: Array<string>;
  setpointLimitMin?: string;
  setpointLimitMax?: string;
  automationHourStart?: Moment;
  automationHourStop?: Moment;
};

export type Location = NonNullable<LocationWithKeysQuery["keysInLocation"]>;

export type Position = {
  positionName?: string;
  positionId: string;
  type: string;
};

export type PositionWithRooms = Position & {
  rooms: Position[];
};

export enum ActingMode {
  TwoPFC = "4pfc_as_2pfc",
  Default = "default",
}

export enum OperationalMode {
  Cooling = "cooling",
  Heating = "heating",
}

export enum EFeatureName {
  ComfortPlus = "ComfortPlus",
}
